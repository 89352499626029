import tabs from './tabs';

$(document).ajaxError(function(event, xhr) {
	if (xhr.status == 401) {
		window.location.replace('/login');
	}
})

$(document).ready(function () {
	$('#logoutButton').on('click tap', function () {
		$('#logoutDialog').dialog('open');
	});

	tabs.init();

	$('#logoutDialog').dialog({
		bgiframe: true,
		autoOpen: false,
		width: 400,
		modal: true,
		resizable: false,
		draggable: false,
		title: 'Log Out',
		buttons: {
			Yes: function () {
				window.location = '/logout';
			},
			No: function () {
				$('#logoutDialog').dialog('close');
			}
		}
	});

	//When page loads...
	$('.tab_content').hide(); //Hide all content

	$('.todayDatepicker').datepicker({
		constrainInput: true,
		dateFormat: 'dd/mm/yy'
	});

	$('.minTodayDatepicker').datepicker({
		constrainInput: true,
		dateFormat: 'dd/mm/yy',
		minDate: '0'
	});

	$('.maxTodayDatepicker').datepicker({
		constrainInput: true,
		dateFormat: 'dd/mm/yy',
		maxDate: '0'
	});

	/*
	* Little helper to toggle the checked boxes depending on how many are currently checked, parent must be a fieldset element.
	*/
	$('body').on('click tap', '[data-toggle="toggle-status-checkboxes"]', function (e) {
		e.preventDefault();

		let $checkboxes = $(this).parent('fieldset').find(':checkbox');
		let $checked = [];
		let $check = true;

		$checkboxes.each(function ($count, $box) {
			if ($($box).prop('checked') === true) {
				$checked.push(true);
			}
		});

		$check = $checked.length >= 0 && $checked.length < $checkboxes.length ? true : false;
		$checkboxes.prop('checked', $check);
	});
});